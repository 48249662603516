import { ISheetPriceData } from "../../interfaces/commonInterface";
import { ITempImageData } from "../../interfaces/orderInterface";

export  const carouselIntialState={
    isOpen:false,
    url:"",
    index:-1,
};

interface IAction{
    payload: typeof carouselIntialState,
    type:"CAROUSEL"
}

export const imageCarousel=(state=carouselIntialState,{payload,type}:IAction)=>{
 if(type==="CAROUSEL"){
  return payload;
 };
 return state;
}


interface IArtowrkData{
    payload:ITempImageData []
    type:"ARTWORK"|"CLEAR_ARTWORK"|"CURRENT_ARTWORK"
}

export const artworkReducer=(state:ITempImageData[]=[],{payload,type}:IArtowrkData)=>{
   switch (type) {
     case "ARTWORK":
       return [...state, ...payload];
     case "CLEAR_ARTWORK":
       return [];
     case "CURRENT_ARTWORK":
       return payload;
     default:
       return state;
   };
};


interface ICommonState{
    booleanState:boolean;
    stringState:string;
    countState:number;
    booleanStateTwo:boolean;
};

export const commonInitalState:ICommonState={
  booleanState:false,
  stringState:"",
  countState:0,
  booleanStateTwo:false
}

export const commonStateReducer=(state=commonInitalState,{payload,type}:{payload:ICommonState,type:string})=>{
  switch (type) {
    case "UPDATE":
        return {...state,...payload};
    case "CURRENT":    
      return payload
    default:
       return state;
  }
};


interface IRemoveState{
  loading:boolean;
  index:number
};

export const removeInitialState:IRemoveState={
  loading:false,
  index:-1
};

export const removeReducer=(state=removeInitialState,{payload,type}:{payload:IRemoveState,type:string})=>{
  switch (type) {
    case "UPDATE":
        return {...state,...payload};
    case "CURRENT":    
      return payload
    default:
       return state;
  }
};

export const sheetPriceReducer=(state=[] as ISheetPriceData[],{payload,type}:
  {payload:ISheetPriceData[],type:"SHEET_PRICE"})=>{
  if(type==="SHEET_PRICE"){
    return payload;
  }
  return state;
};


export interface ISheetDimesion{
  name:string;
  value:string;
};

export const sheetDimesionReducer=(state=[] as ISheetDimesion[],{payload,type}:
  {payload:ISheetDimesion[],type:"UPDATE"|"CURRENT"})=>{
    switch (type) {
      case "UPDATE":
          return {...state,...payload};
      case "CURRENT":    
        return payload
      default:
         return state;
    };
};

